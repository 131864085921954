<template>
    <v-list-group v-if="doShowItem && !isSubGroup"
        active-class="accent--text"
        no-action
        :subGroup="isSubGroup" 
        :to="{ name: item.name }">
        <template v-slot:activator>
            <v-list-item-content>
                <v-list-item-title>{{ item.displayName }}</v-list-item-title>
            </v-list-item-content>
        </template>
        <template v-slot:appendIcon>
            <v-icon>mdi-chevron-down</v-icon>
        </template>
        <template v-slot:prependIcon>
            <v-icon>{{ item.icon }}</v-icon>
        </template>
        <BT-App-Office-Drawer-Item
            v-for="child in relevantChildren"
            :key="child.name"
            :item="child"
            isSubGroup />
    </v-list-group>
    <v-list-item v-else-if="doShowItem && isSubGroup" :to="{ name: item.name }" active-class="accent--text">
        <v-list-item-title>{{ item.displayName }}</v-list-item-title>
        <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>
    </v-list-item>
    <v-divider v-else-if="item.isDivider" />
    <div v-else />
</template>

<script>
import { isLengthyArray } from '~tools/helpers.js';

    export default {
        name: 'BT-App-Office-Drawer-Item',
        components: {
            BTAppOfficeDrawerItem: () => import('~components/BT-App-Office-Drawer-Item.vue')
        },
        data: function() {
            return {
                childrenToShow: [],
                doShowItem: false,
            }
        },
        props: {
            item: null,
            isSubGroup: {
                type: Boolean,
                default: false
            },
            subFilter: {
                type: Array,
                default: null
            }
        },
        mounted() {
            this.loadChildren();
        },
        computed: {
            relevantChildren() {
                if (this.isLengthyArray(this.subFilter)) {
                    return !this.isLengthyArray(this.item.children) ? [] : this.item.children.filter(x => x.isInNavMenu !== false).filter(x => !isLengthyArray(x.subFilters) || x.subFilters.some(y => this.subFilter.some(f => f == y)));
                }
                else {
                    return !this.isLengthyArray(this.item.children) ? [] : this.item.children.filter(x => x.isInNavMenu !== false);
                }
            }
        },
        methods: {
            findIcon(item) {
                return item.icon ? item.icon : 'mdi-home';
            },
            loadChildren() {
                for (var i = 0; i < this.relevantChildren.length; i++) {
                    let child = this.relevantChildren[i];
                    if (this.$BlitzIt.auth.doShowByNavName(child, true)) {
                        this.childrenToShow.push(child);
                    }
                }
                this.doShowItem = this.$BlitzIt.auth.doShowByNavName(this.item, true);
            }
        }
    }
</script>